<template>
  <Modal v-if="isModalShown">
    <template v-slot:headline>{{ headerTitle }}</template>
    <template v-slot:body>
      <FormRow :required="true">
        <template v-slot:label>配信時期</template>
        <template v-slot:content>
          <div class="form-content-row">
            <ul class="listGroup">
              <li
                v-for="item in sendNotificationTypes"
                :key="item.value"
                :class="{ 'listGroup-item bd-0': true, 'd-flex justify-content-between align-items-end': item.value === schedulingType }"
              >
                <label class="form-radio d-inline-block">
                  <input
                    :class="{ 'form-radio-input': true,  'is-error': errors.type }"
                    type="radio"
                    name="type"
                    v-model="form.type"
                    :value="item.value"
                  />
                  <span class="form-radio-label">{{ item.label }}</span>
                </label>
                <div class="block-select d-flex align-items-center" v-if="item.value === schedulingType">
                  <div class="form-select ml-20">
                    <select :class="{ 'form-control form-select-input': true, 'is-error': errors.years || errors.date }" name="date" :disabled="!isSchedulingType" v-model="form.years">
                      <option value=""></option>
                      <option v-for="item in years" :key="item.key" :value="item.value">{{ item.value }}</option>
                    </select>
                  </div>
                  <p>年</p>
                  <div class="form-select ml-20">
                    <select :class="{ 'form-control form-select-input': true, 'is-error': errors.months || errors.date }" name="date" :disabled="!isSchedulingType" v-model="form.months">
                      <option value=""></option>
                      <option v-for="item in months" :key="item.key" :value="item.value">{{ item.value }}</option>
                    </select>
                  </div>
                  <p>月</p>
                  <div class="form-select ml-20">
                    <select :class="{ 'form-control form-select-input': true, 'is-error': errors.days || errors.date }" name="date" :disabled="!isSchedulingType" v-model="form.days">
                      <option value=""></option>
                      <option v-for="item in days" :key="item.key" :value="item.value">{{ item.value }}</option>
                    </select>
                  </div>
                  <p>日</p>
                  <div class="form-select ml-20">
                    <select :class="{ 'form-control form-select-input': true, 'is-error': errors.hours || errors.date }" name="date" :disabled="!isSchedulingType" v-model="form.hours">
                      <option value=""></option>
                      <option v-for="item in hours" :key="item.key" :value="item.value">{{ item.value }}</option>
                    </select>
                  </div>
                  <p>時</p>
                </div>
              </li>
            </ul>
          </div>
        </template>
      </FormRow>
      <FormRow :required="true">
        <template v-slot:label>{{ isNotNotifyModal ? 'テンプレート' : 'タイトル' }}</template>
        <template v-slot:content>
          <div class="form-content-row">
            <div class="form-select" v-if="isNotNotifyModal">
              <select
                :class="{ 'form-control form-select-input custom-form-select': true, 'is-error': errors.templateId }"
                name="templateId"
                v-model="form.templateId"
              >
                <option value="" selected>選択してください</option>
                <option
                  v-for="item in dataList"
                  :key="item.id"
                  :value="item.id"
                >{{ item.name }}</option>
              </select>
            </div>
            <input
              v-else
              :class="{ 'form-control': true, 'is-error': errors.message }"
              type="text"
              placeholder="40文字以内"
              name="message"
              maxlength="40"
              v-model="form.message"
              v-trim
              v-maxlength
            />
          </div>
        </template>
      </FormRow>
    </template>
    <template v-slot:footer>
      <ul class="listGrid justify-content-end">
        <li class="listGrid-item">
          <button
            class="btn btn-white"
            type="button"
            @click="handleCloseModal"
          >
            キャンセル
          </button>
        </li>
        <li class="listGrid-item">
          <ActionButton class="btn btn-main" :handle-submit="handlePushModal" :button-text="buttonText" />
        </li>
      </ul>
    </template>
  </Modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, maxLength } from 'vuelidate/lib/validators';
//components
import FormRow from '@/components/FormRow.vue';
import Modal from '@/components/Modal.vue';
//mixins
import modal from '@/mixins/plugin/modal';
import error from '@/mixins/plugin/error';
//helpers
import { EventConstants } from '@/constants/event';
import { convertNumberToArrayWithKey, convertStringsToDateTime } from '@/helpers/formatData';

export default {
  components: {
    Modal,
    FormRow,
  },
  data: function() {
    return {
      form : {
        type: 1,
        templateId: '',
        message: '',
        years: '',
        months: '',
        days: '',
        hours: '',
      },
      schedulingType: EventConstants.sendNotificationType.scheduling.value,
      convertNumberToArrayWithKey,
    };
  },
  mixins: [modal, error],
  props: {
    modalType: {
      type: String,
      default: '',
    },
    handleCloseModal: {
      type: Function,
      required: true
    },
    headerTitle: {
      type: String,
      default: 'プッシュ通知',
    },
    buttonText: {
      type: String,
      default: '配信する',
    },
    isModalShown: {
      type: Boolean,
      default: false,
    },
  },
  validations() {
    let form = {
      type: { required },
      years: {},
      months: {},
      days: {},
      hours: {},
      templateId: {},
      message: {},
    };
    if (this.isNotNotifyModal) {
      form.templateId = { selectRequired: required };
    } else {
      form.message = { required, maxLength: maxLength(40) };
    }
    if (this.isSchedulingType) {
      if (!this.form.years && !this.form.months && !this.form.days && !this.form.hours) {
        form = {
          ...form,
          formDateRequired: () => {
            this.errors.years = true;
            this.errors.months = true;
            this.errors.days = true;
            this.errors.hours = true;
            this.$message.formDateRequired();
            return false;
          },
        }
      } else if (!this.form.years || !this.form.months || !this.form.days || !this.form.hours) {
        form = {
          ...form,
          years: { selectRequired: required },
          months: { selectRequired: required },
          days: { selectRequired: required },
          hours: { selectRequired: required },
        }
      }
    }
    return { form };
  },
  computed: {
    ...mapGetters({
      templatesList: 'customer/templatesList',
      newsList: 'customer/newsList',
    }),
    subdomain() {
      return this.$route.params.subdomain;
    },
    dataList() {
      if (!this.isNotNotifyModal) return [];
      return this.isCouponModal ? this.templatesList : this.newsList;
    },
    sendNotificationTypes() {
      return Object.values(EventConstants.sendNotificationType);
    },
    isSchedulingType() {
      return this.form.type === this.schedulingType;
    },
    isNotNotifyModal() {
      return this.modalType !== 'notificationModal';
    },
    isCouponModal() {
      return this.modalType === 'couponModal';
    },
    isNewsModal() {
      return this.modalType === 'newsModal';
    },
    years() {
      const currentYear = convertNumberToArrayWithKey(10000);
      return currentYear.slice(new Date().getFullYear() - 1, new Date().getFullYear() + 10);
    },
    months() {
      return convertNumberToArrayWithKey(12);
    },
    days() {
      const lastDateInMonth = new Date(this.form.years, this.form.months, 0).getDate();
      return convertNumberToArrayWithKey(lastDateInMonth);
    },
    hours() {
      return convertNumberToArrayWithKey(24, 0);
    },
    convertedDate() {
      return convertStringsToDateTime(this.form?.years, this.form?.months, this.form?.days, this.form?.hours);
    }
  },
  watch: {
    'form.months'(val) {
      const selectedMonth = parseInt(val, 10);
      const selectedDay = parseInt(this.form.days, 10);
      const lastDayInMonth = new Date(this.form.years, selectedMonth, 0).getDate();
      if (selectedDay > lastDayInMonth) this.form.days = '';
    },
    'form.type'(val) {
      if (val === EventConstants.sendNotificationType.immediately.value) {
        this.resetDateForm();
      }
    },
    isModalShown(val) {
      this.resetForm();
      if (val && this.isNotNotifyModal) {
        this.getTemplateList();
      }
    }
  },
  methods: {
    async getTemplateList() {
      if (this.isCouponModal) {
        return await this.$store.dispatch('customer/getTemplatesList', { subdomain: this.subdomain, getAll: 1 });
      } else if (this.isNewsModal) {
        return await this.$store.dispatch('customer/getNewsList', { subdomain: this.subdomain, getAll: 1 });
      }
    },
    resetForm() {
      this.form =  {
        type: 1,
        templateId: '',
        message: '',
      };
      this.resetDateForm();
    },
    resetDateForm() {
      this.form = {
        ...this.form,
        years: '',
        months: '',
        days: '',
        hours: '',
      }
      this.errors.years = false;
      this.errors.months = false;
      this.errors.days = false;
      this.errors.hours = false;
    },
    handlePushModal() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.getErrors(this.$v.form, 'registNotice');
        return false;
      } else {
        this.errors = {};
        this.$message.reset();
        let data = {
          type: this.form?.type,
          date: this.convertedDate,
          message: this.form?.message,
          templateId: this.form?.templateId,
        }
        if (!this.isSchedulingType) delete data.date;
        if (!this.isNotNotifyModal) {
          delete data.templateId;
        } else {
          delete data.message;
        }
        this.$emit('handle-push-modal', this.modalType, data);
      }
    },
  }
};
</script>

<style scoped>
.block-select > .form-select {
  margin-right: 5px;
}
</style>
